<template>
  <b-card
    v-if="!areBothTabsDisabled"
    class="dashboard__card p-0"
    :class="isDarkSkin ? 'dashboard__card--dark' : ''"
  >
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col sm="4" class="d-flex align-items-center" style="gap: 5px">
            <b-avatar
              rounded="sm"
              size="4em"
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            >
              <div class="ico_content">
                <amg-icon icon="MoneyBagIcon" class="text-primary" size="20" />
              </div>
            </b-avatar>
            <span class="card-title-custom">
              Expenses
              <feather-icon
                v-if="expensesToReviewCounter"
                icon="AlertTriangleIcon"
                size="20"
                badge-classes="bg-warning"
                :badge="expensesToReviewCounter"
                v-b-tooltip.hover.top.v-warning="'Autogenerated Expenses'"
                class="text-warning clickable"
                @click="showExpensesToReviewModal = true"
              />
            </span>
          </b-col>

          <b-col
            sm="8"
            class="d-flex justify-content-end"
            style="gap: 10px; max-height: 36px"
          >
            <v-select
              v-if="!showDate"
              v-model="selectYear"
              :reduce="(user) => user.value"
              :options="optionYears"
              :selectable="(option) => option.selectable === true"
              :clearable="false"
              label="text"
              style="width: 150px"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  Please select an option
                </b-form-select-option>
              </template>
            </v-select>

            <v-select
              v-if="!showDate"
              v-model="selectMonth"
              :reduce="(user) => user.value"
              :options="optionMonths"
              :clearable="false"
              label="text"
              style="width: 150px"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  Please select an option
                </b-form-select-option>
              </template>
            </v-select></b-col
          >
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <template v-if="!isLoadingValidate">
            <b-tabs
              v-model="activeTab"
              active-tab-class="p-0 "
              pills
              nav-class="mb-0 mt-1"
              active-nav-item-class="bg-info box-shadow-info border-info info"
            >
              <b-tab
                v-for="(item, index) in tabs"
                :key="index"
                :title="item.title"
                :active="index === 0"
                :disabled="
                  (index === 0 &&
                    (!isAssignCardModule || !(isSupervisor || isChief))) ||
                  (index === 1 && !isAssignCheckModule) || 
                  (index === 2 && !isAssignEcheckModule)
                "
                class="mb-1"
              >
                <b-card
                  no-body
                  class="border-top-info border-3 border-table-radius px-1"
                >
                  <list-expense
                    v-if="activeTab === index"
                    :isLoading="isLoading"
                    :items="items"
                    :tab="typeTab"
                    @openDetail="openDetail"
                    @openModalExpense="openModalExpense"
                    @refresh="getDepartmentExpenses"
                  />
                </b-card>
              </b-tab>
            </b-tabs>
          </template>
          <template v-else>
            <p class="text-center">Loading...</p>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <add-or-edit-expense
      v-if="showAddExpense"
      v-bind="modalExpenseScope.attr"
      v-on="modalExpenseScope.events"
      :tab="typeTab"
      :hasCreditCard="isAssignCardModule"
      :hasCheck="isAssignCheckModule"
    />
    <detail-department-expenses
      v-if="showDetail"
      :module_id="moduleId"
      :month="showDate ? monthDash : selectMonth"
      :year="showDate ? yearDash : selectYear"
      @close="showDetail = false"
      :dataRow="dataRow"
      :status="status"
      :currentTab="typeTab"
    />

    <ExpensesToReviewRPModal
      v-if="showExpensesToReviewModal"
      @reload="getDepartmentExpenses()"
      @close="showExpensesToReviewModal = false"
      @updateCounter="getExpensesToReviewCounter"
    />
  </b-card>
</template>

<script>
import moment from "moment";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";
import AddOrEditExpense from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import ListExpense from "@/views/commons/expenses/components/department/ListExpense.vue";
import DetailDepartmentExpenses from "@/views/commons/expenses/components/Modal/DetailDepartmentExpenses.vue";
import ExpensesToReviewRPModal from "@/views/commons/expenses/components/Modal/ExpensesToReviewRPModal.vue";
export default {
  props: {
    showDate: {
      type: Boolean,
      default: false,
    },
    yearDash: {
      type: Number,
      required: false,
      default: null,
    },
    monthDash: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    AddOrEditExpense,
    ListExpense,
    DetailDepartmentExpenses,
    ExpensesToReviewRPModal,
  },
  data() {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 2024 + 1 },
      (_, i) => 2024 + i
    ).map((year) => ({ text: year, value: year }));
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    return {
      isLoading: false,
      isLoadingValidate: false,
      selectMonth: currentMonth,
      selectYear: moment().year(),
      optionMonths: [
        { text: "All", value: null },
        ...moment
          .months()
          .map((month, index) => ({ text: month, value: index + 1 })),
      ],
      optionYears: years,
      showAddExpense: false,
      modalExpense: {
        show: false,
        item: null,
      },
      showExpensesToReviewModal: false,
      expensesToReviewCounter: 0,
      isAssignCardModule: false,
      existCardExpenses: false,
      isAssignCheckModule: false,
      isAssignEcheckModule: false,
      tabs: [
        {
          title: "CARDS",
          type: "CREDIT_CARD",
        },
        {
          title: "CHECKS",
          type: "CHECK",
        },
         {
          title: "E-CHECKS",
          type: "E-CHECK",
        },
      ],
      activeTab: 0,
      items: [],
      showDetail: false,
      dataRow: {},
      status: null,
    };
  },
  computed: {
    moduleId() {
      return this.openFromDashboardCeo ? 1 : this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    modalExpenseScope() {
      return {
        attr: {
          item: this.modalExpense.item,
        },
        events: {
          close: () => {
            this.showAddExpense = false;
            this.modalExpense.item = null;
          },
          reload: async ({ tab }) => {
            const tabIndex = this.tabs.findIndex((item) => item.type === tab);
            if (tabIndex === this.activeTab) {
              await this.getDepartmentExpenses();
            } else {
              this.activeTab = tabIndex;
            }
          },
        },
      };
    },
    typeTab() {
      const index = this.activeTab;
      return this.tabs[index].type;
    },
    areBothTabsDisabled() {
      return (
        (!this.isAssignCardModule || !(this.isSupervisor || this.isChief)) &&
        !this.isAssignCheckModule
      );
    },
  },
  watch: {
    selectYear() {
      this.getDepartmentExpenses();
    },
    selectMonth() {
      this.getDepartmentExpenses();
    },
    yearDash() {
      this.getDepartmentExpenses();
    },
    monthDash() {
      this.getDepartmentExpenses();
    },
    async existCardExpenses(value) {
      if (value && this.isAssignCardModule) {
        await this.getDepartmentExpenses();
      }
    },
    async typeTab(value) {
      await this.getDepartmentExpenses();
    },
  },
  async created() {
    if (this.openFromDashboardCeo) {
      this.existCardExpenses = true;
      this.isAssignCardModule = false;
      this.isAssignCheckModule = true;
    } else {
      await this.existCardModule();
    }
      await this.getExpensesToReviewCounter();
  },
  methods: {
    async existCardModule() {
      try {
        this.isLoadingValidate = true;
        const data = await ExpensesService.existCardModule({
          module_id: this.moduleId,
          program_id: this.programId,
        });
        this.existCardExpenses = data.exists_card_expenses;
        this.isAssignCardModule = data.has_credit_card_assigned;
        this.isAssignCheckModule = data.has_bank_accounts;
        this.isAssignEcheckModule = data.has_echeck_assigned;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoadingValidate = false;
      }
    },
    openModalExpense() {
      this.showAddExpense = true;
    },
    async getExpensesToReviewCounter() {
      try {
        const { counter } = await ExpensesService.getExpensesToReviewCounter({
          module_id: this.moduleId,
        });
        this.expensesToReviewCounter = counter;
        if (counter) this.showExpensesToReviewModal = true;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getDepartmentExpenses() {
      try {
        this.isLoading = true;
        const { data } = await ExpensesService.getDepartmentExpenses({
          module_id: this.moduleId,
          year: !this.showDate ? this.selectYear : this.yearDash,
          month: !this.showDate ? this.selectMonth : this.monthDash,
          program_id: this.programId,
          method: this.typeTab,
        });
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    openDetail(data) {
      const { item, status } = data;
      this.showDetail = true;
      this.status = status;
      this.dataRow = { ...item, method: this.typeTab };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title-custom {
  font-size: 1.4rem;
  font-weight: bold !important;
  margin: 0 !important;
}
</style>
