<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-end" style="gap: 10px">
        <b-button
          class="mt-1"
          variant="outline-secondary"
          v-b-tooltip.hover.top.v-secondary="'REFRESH'"
          @click="$emit('refresh')"
        >
          <feather-icon icon="RefreshCcwIcon" size="15" />
        </b-button>
        <b-button
          class="mt-1"
          variant="outline-success"
          v-b-tooltip.hover.top.v-success="titleBtn"
          @click="openModalExpense()"
        >
          <tabler-icon icon="PlusIcon" size="15" />
          {{ titleBtn }}
        </b-button>
      </b-col>
      <b-col sm="12" class="">
        <b-skeleton-table
          v-if="isLoading"
          :columns="5"
          :rows="5"
          width="100%"
          animated
        >
        </b-skeleton-table>
        <b-table-simple
          v-else
          responsive
          class="custom-table-expenses"
          sticky-header="60vh"
        >
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th colspan="2" class="text-center bg-in-process"
                >IN PROCESS</b-th
              >
              <b-th colspan="2" class="text-center bg-confirmed"
                >CONFIRMED</b-th
              >
              <b-th colspan="2" class="text-center bg-rejected">REJECTED</b-th>
            </b-tr>
            <b-tr class="sub-row">
              <b-th></b-th>
              <b-th class="text-center bg-amount">$</b-th>
              <b-th class="text-center bg-quantity">QUANTITY</b-th>
              <b-th class="text-center bg-amount">$</b-th>
              <b-th class="text-center bg-quantity">QUANTITY</b-th>
              <b-th class="text-center bg-amount">$</b-th>
              <b-th class="text-center bg-quantity">QUANTITY</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in items" :key="index">
              <b-td class="custom-foot"
                ><strong>{{ item.type }}</strong></b-td
              >
              <b-td
                @click="openDetail(item, 'IN PROCESS', item.total_inprocess)"
                :class="{ active: item.total_inprocess > 0 }"
              >
                {{ item.total_inprocess | currencyTwo | myMoney }}
              </b-td>
              <b-td
                @click="openDetail(item, 'IN PROCESS', item.quantity_inprocess)"
                :class="{ active: item.quantity_inprocess > 0 }"
              >
                {{ item.quantity_inprocess || 0 }}
              </b-td>
              <b-td
                @click="openDetail(item, 'APPROVED', item.total_confirmed)"
                :class="{ active: item.total_confirmed > 0 }"
              >
                {{ item.total_confirmed | currencyTwo | myMoney }}
              </b-td>
              <b-td
                @click="openDetail(item, 'APPROVED', item.quantity_confirmed)"
                :class="{ active: item.quantity_confirmed > 0 }"
              >
                {{ item.quantity_confirmed || 0 }}
              </b-td>
              <b-td
                @click="openDetail(item, 'REJECTED', item.total_rejected)"
                :class="{ active: item.total_rejected > 0 }"
              >
                {{ item.total_rejected | currencyTwo | myMoney }}
              </b-td>
              <b-td
                @click="openDetail(item, 'REJECTED', item.quantity_rejected)"
                :class="{ active: item.quantity_rejected > 0 }"
              >
                {{ item.quantity_rejected || 0 }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td class="custom-foot"><strong>TOTAL</strong></b-td>
              <b-td> {{ total.inProcess.amount | currencyTwo | myMoney }}</b-td>
              <b-td>{{ total.inProcess.quantity || 0 }}</b-td>
              <b-td> {{ total.confirmed.amount | currencyTwo | myMoney }}</b-td>
              <b-td>{{ total.confirmed.quantity || 0 }}</b-td>
              <b-td> {{ total.rejected.amount | currencyTwo | myMoney }}</b-td>
              <b-td>{{ total.rejected.quantity || 0 }}</b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-col>
      <b-col class="d-flex justify-content-end" v-if="tab === 'CREDIT_CARD'">
        <b-button
          variant="info"
          @click="showRPModal = true"
          style="padding: 5px"
        >
          Show recurring payments
        </b-button>
      </b-col>
    </b-row>
    <RecurringPaymentsModal
      v-if="showRPModal && tab === 'CREDIT_CARD'"
      @close="showRPModal = false"
    />
  </div>
</template>
<script>
import RecurringPaymentsModal from "@/views/commons/expenses/components/Modal/RecurringPaymentsModal.vue";

export default {
  name: "ListExpense",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    tab: {
      required: true,
      type: String,
    },
  },
  components: {
    RecurringPaymentsModal,
  },
  data() {
    return {
      showRPModal: false,
    };
  },
  computed: {
    titleBtn() {
      const obj = {
        CREDIT_CARD: "REGISTER EXPENSE",
        CHECK: "REQUEST CHECK",
        "E-CHECK": "REQUEST E-CHECK",
      };
      return obj[this.tab];
    },
    total() {
      const inProcess = this.items.reduce(
        (acc, item) => ({
          amount: acc.amount + item.total_inprocess,
          quantity: acc.quantity + item.quantity_inprocess,
        }),
        { amount: 0, quantity: 0 }
      );
      const confirmed = this.items.reduce(
        (acc, item) => ({
          amount: acc.amount + item.total_confirmed,
          quantity: acc.quantity + item.quantity_confirmed,
        }),
        { amount: 0, quantity: 0 }
      );
      const rejected = this.items.reduce(
        (acc, item) => ({
          amount: acc.amount + item.total_rejected,
          quantity: acc.quantity + item.quantity_rejected,
        }),
        { amount: 0, quantity: 0 }
      );
      const total = {
        inProcess,
        confirmed,
        rejected,
      };
      return total;
    },
  },
  methods: {
    openDetail(item, status, total) {
      if (total === 0) return;
      this.$emit("openDetail", { item, status });
    },
    openModalExpense() {
      this.$emit("openModalExpense");
    },
  },
};
</script>
<style lang="scss" scoped>
.card-title-custom {
  font-size: 1.4rem;
  font-weight: bold !important;
  margin: 0 !important;
}
.custom-table-expenses::v-deep {
  table {
    border-collapse: separate !important;
    border-spacing: 0px 8px !important;
    padding: 0 15px !important;

    tr td {
      border: none !important;
      background: #fafafa !important;
      padding: 15px 0 !important;
      text-align: center !important;
      transition: box-shadow 0.3s ease !important;
    }

    tr td:first-child {
      border-radius: 10px 0 0 10px !important;
    }

    tr td:last-child {
      border-radius: 0 10px 10px 0 !important;
      background: #fafafa !important;
    }

    thead {
      tr:first-of-type {
        transform: translateY(10px);
      }

      tr:first-of-type th {
        padding: 10px 0 !important;
      }

      tr:first-of-type th:nth-child(2) {
        border-radius: 15px 0 0 0 !important;
      }

      tr:first-of-type th:last-child {
        border-radius: 0 15px 0 0 !important;
      }

      tr th {
        padding: 5px 0 !important;
      }

      tr th:first-child {
        background: none !important;
        border-radius: 0 !important;
        border: none !important;
      }

      .bg-in-process {
        background: #3f7afa !important;
        color: white !important;
      }

      .bg-rejected {
        background: #fc424a !important;
        color: white !important;
      }

      .bg-confirmed {
        background: #00d25b !important;
        color: white !important;
      }

      .bg-amount,
      .bg-quantity {
        background-image: linear-gradient(47deg, #0090e7, #1ba9ff);
        color: white !important;
      }
    }
    tbody tr td {
      cursor: not-allowed;
    }
    tbody tr td.active {
      text-decoration: underline;
      text-decoration-color: #3f7afa;
      text-underline-offset: 5px;
      color: #3f7afa !important;
    }
    tbody tr td.active:hover {
      cursor: pointer !important;
      box-shadow: rgba(0, 144, 231, 0.3) 0px 30px 60px -12px inset,
        rgba(0, 144, 231, 0.3) 0px 18px 36px -18px inset;
    }

    .custom-foot {
      background: #3f7afa !important;
      color: #fff !important;
    }
  }
}

.dark-layout {
  .custom-table-expenses {
    table {
      tr td {
        background: #1b1b1e !important;
      }

      tr td:last-child {
        background: #1b1b1e !important;
      }

      .custom-foot {
        background: #3f7afa !important;
        color: #fff !important;
      }
    }
  }
}
</style>
