<template>
  <div>
    <b-modal
      ref="expenses-to-review-rp-modal"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      :title="title"
      size="xmd"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="AlertTriangleIcon"
              size="30"
              badge-classes="bg-warning"
              class="text-warning font-weight-bolder"
            />
            <span>{{ title }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div>
        <filter-slot
          :filter="[]"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['expenses-to-review-table'].refresh()"
        >
          <b-table
            slot="table"
            ref="expenses-to-review-table"
            id="expenses-to-review-table"
            sticky-header="70vh"
            small
            show-empty
            no-provider-filtering
            :busy.sync="isBusy"
            responsive="sm"
            :items="myProvider"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            :has-provider="true"
            class="position-relative table-new-customization"
            :class="['text-center']"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(payable_description)="{ item }">
              <span>{{ item.payable_description }}</span>
              <!-- <feather-icon
                size="15"
                icon="MapPinIcon"
                class="ml-1 text-info"
                v-b-tooltip.hover.top.v-info="item.address"
              >
              </feather-icon> -->
            </template>
            <template #cell(expense_description)="{ item }">
              <span class="text-info">{{ item.expense_description }}</span>
            </template>
            <template #cell(method)="{ item }">
              <template v-if="item.method == 'CREDIT_CARD'">
                <div class="row">
                  <div class="col-4 p-0 justify-content-end d-flex">
                    <type-card :type_card="item.type_card" />
                  </div>
                  <div
                    class="col-8 p-0 d-flex justify-content-center align-items-center"
                  >
                    <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
                  </div>
                </div>
              </template>
              <template v-else>
                <b-badge variant="primary">{{ item.method }}</b-badge>
              </template>
            </template>
            <template #cell(amount)="{ item }">
              <span>$ {{ item.amount | currency }}</span>
            </template>
            <template #cell(date)="{ item }">
              <span>{{ item.date | myGlobal }}</span>
            </template>
            <template #cell(start_date)="{ item }">
              <span>{{ item.start_date | myGlobal }}</span>
            </template>
            <template #cell(user_name)="{ item }">
              <div>
                <div>{{ item.user_name }}</div>
                <div>{{ item.created_at | myGlobalDay }}</div>
              </div>
            </template>
            <template #cell(action)="{ item }">
              <span class="d-flex justify-content-center">
                <tabler-icon
                  icon="BrandTelegramIcon"
                  class="text-success clickable mx-1"
                  size="22"
                  v-b-tooltip.hover.top.v-success="'Send to CEO'"
                  @click="openModalExpense(item)"
                />
                <!-- <tabler-icon
                  icon="EditIcon"
                  class="text-warning clickable mx-1"
                  size="22"
                  v-b-tooltip.hover.top.v-warning="'Edit'"
                  @click="openModalExpense(item)"
                /> -->
              </span>
            </template>
          </b-table>
        </filter-slot>
      </div>
    </b-modal>
    <AddOrEditExpense
      v-if="modalExpense.show"
      v-bind="modalExpenseScope.attr"
      v-on="modalExpenseScope.events"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";
import AddOrEditExpense from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  name: "ExpensesToReviewRPModal",
  props: {},
  components: {
    AddOrEditExpense,
    TypeCard,
  },
  data() {
    return {
      modalExpense: {
        show: false,
        item: null,
      },
      filter: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Description...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      items: [],
      isBusy: false,
      fields: [
        {
          key: "payable_description",
          label: "Vendor",
          visible: true,
        },
        {
          key: "expense_description",
          label: "Concept",
          visible: true,
        },
        {
          key: "method",
          label: "Method",
          visible: true,
        },
        {
          key: "address",
          label: "Address",
          visible: false,
        },
        {
          key: "date",
          label: "Payment Date",
          visible: true,
        },
        {
          key: "start_date",
          label: "Next Payment",
          visible: false,
        },
        {
          key: "user_name",
          label: "Created by",
          visible: true,
        },
        {
          key: "amount",
          label: "Amount",
          visible: true,
        },

        {
          key: "action",
          label: "Actions",
          visible: true,
        },
      ],
      totalRows: null,
    };
  },
  computed: {
    modalExpenseScope() {
      return {
        attr: {
          item: {
            ...this.modalExpense.item,
            clients_expenses: [],
          },
        },
        events: {
          close: () => {
            this.modalExpense.show = false;
            this.modalExpense.item = null;
          },
          reload: () => {
            this.sendToCEO();
          },
        },
      };
    },
    title() {
      return `YOU HAVE AUTO-GENERATED EXPENSES TO REVIEW!`;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    namePath() {
      return this.$route.name;
    },

    filteredFields() {
      return this.fields.filter((field) => field.visible);
    },

    programId() {
      return this.moduleId == 2 ? 0 : this.$route.matched[1].meta.program;
    },
  },
  watch: {},
  async mounted() {
    this.toggleModal("expenses-to-review-rp-modal");
  },
  async created() {},
  methods: {
    openModalExpense(item) {
      this.modalExpense.show = true;
      this.modalExpense.item = item;
    },
    refreshTable() {
      this.$refs["expenses-to-review-table"].refresh();
    },
    async sendToCEO() {
      try {
        const { message } = await ExpensesService.sendToCEO({
          ...this.modalExpense.item,
          user_id: this.currentUser.user_id,
        });
        this.showSuccessSwal(message);
        this.refreshTable();
        this.$emit("reload");
        this.$emit("updateCounter");
      } catch (e) {
        console.log(e);
        this.showErrorSwal(e);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `key`);
      field.visible = bool;
    },
    async updateRecurringPaymentStatus(item) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) {
        item.is_active = !item.is_active;
        return;
      }
      try {
        const params = {
          id: item.id,
          is_active: item.is_active,
        };
        const data = await ExpensesService.setIsActive(params);
        this.showSuccessSwal(data.message);
      } catch (e) {
        console.log(e);
        item.is_active = !item.is_active;
        this.showErrorSwal(e);
      }
    },
    async myProvider() {
      try {
        const params = {
          module_id: this.moduleId,
          per_page: this.paginate.perPage,
          current_page: this.paginate.currentPage,
          filter_model: this.filterPrincipal.model,
        };
        const { data } = await ExpensesService.getExpesesToReview(params);
        this.items = data.data;
        this.startPage = data.from;
        this.toPage = data.to;
        this.totalRows = data.total;
        return this.items;
      } catch (e) {
        console.log(e);
        this.showErrorSwal(e);
        return [];
      }
    },
    getFilterByKey(key) {
      const filter = this.filter.find((filter) => filter.key === `key`);
      return filter;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
